.opconfig-flex-container {
  display: flex;
  justify-content: space-between;
}

.box-container {
  box-shadow: 1px 1px 2px #888888;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin: 10px;
}

.responses {
  width: 40%;
  padding: 5px;
}

.opConfig-button-container {
  display: flex;
}

.submitButton {
  margin-left: 77.9%;
  margin-right: 10px;
  margin-bottom: 8px;
}

.cancelButton {
  margin-bottom: 8px;
}
