.field-input {
  font-size: 13px;
  margin: 3px;
  display: flex;
  align-items: center;
}

.field-input > label:first-child {
  width: 36%;
  font-size: 15px;
}

.field-input > label:last-child {
  color: #888888;
  font-size: 15px;
}

.valid {
  border-style: solid;
  border-radius: 3px;
  padding: 0px 3px;
  border: 1px solid #ababab;
  width: 36%;
  height: 27px;
  margin: 1px;
  background-color: #f5f5f5;
}

.valid:disabled {
  background-color: #eaeaea;
  border: 1px solid #ababab;
}

.error {
  border-style: solid;
  border-radius: 3px;
  padding: 0px 3px;
  border: 1px solid red;
  width: 36%;
  height: 27px;
  margin: 1px;
  background-color: #f5f5f5;
}

.error:focus {
  outline: none;
}
