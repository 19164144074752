.problem-items-submit-button-padding {
    margin-top: 5.5em;
}

.button {
    width: fit-content;
    position:absolute;
    top: 12px;
    right: 16px;
}

.item-details {
    padding-top: 1em;
}

.problem-item-deletion-details { 
    border: 1px solid #CECECE; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
    margin : 0.5em 10em 1em 1em;
    padding: 1em;
    width: 78em;
    position: absolute;
}

.total-problem-item-details {
    background-color: #f5f5f5;
    display: inline;
    border: 1px solid #CCC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 1em;
    text-align: center;
}

.problem-item-summary-title {
    padding: 0.5em 1em 0.5em 1em ;
    background-color: #d0d0d0;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width:  17.9em;
}

.inbound-page-title {
    margin-bottom: 1em;
    margin-left: -1em;
}

.delete-problem-items-list-input {
    width: 12em;
}
.delete-problem-items-list-params {
    float: left;
    color: black;
    margin-top: 0em;
    margin-left: 3em;
    margin-right: 3em;
}

.delete-problem-items-warehouse-params {
    float: left;
    color: black;
    margin-top: 0em;
    margin-left: 3em;
    margin-right: 5.7em;
}

.delete-problem-items-warehouse-input {
    margin-left: 4.7em;
    margin-right: 2em;
    width: 12em;
}

.success-deletion {
    margin-bottom: 1em;
}

.delete-problem-items-warehouse-input-box {
    height: 3em;
}

.delete-problem-items-list-input-box {
    height: 6em;
}

