.removal-submit-button-padding {
    padding: 0.5em 1em 2.5em 0.5em
}
.removal-warehouse-input-box {
    margin-left: 2em !important;
    width: 12em;
}
.removal-orderId-input-box {
    margin-left: 3.5em !important;
    width: 12em;
}
.vendor-return-summary {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 30em;
}
.removal-details {
    border: 1px solid #CECECE;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    margin : 0.5em 1em 1em 1em;
    padding: 1em;
    min-width: 1250px;
}
.removal-item-details {
    padding-top: 1em;
}
.removal-shipment-details {
    padding-top: 1em;
}
.removal-total-item-details {
    background-color: #f5f5f5;
    display: inline;
    border: 1px solid #CCC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 1em;
}
.removal-id {
    margin-bottom: 1em;
    margin-left: -1em;
}
.removal-order {
    color: orange;
    font-size: large;
    text-align: left;
}
.vendor-return-summary-title {
    padding: 0.5em 1em 0.5em 1em ;
    background-color: #d0d0d0;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.removal-alert {
    padding: 0.5em;
}
.removal-alert-complete {
    background-color: #C6FF86;
}
.removal-alert-inprocess {
    background-color: #f6d3bb;
}
.removal-alert-error {
    background-color: lightcoral;
}
.circular-progress {
    margin-left: 50%;
}
.track-shipment {
    margin-right: 1em !important;
    text-decoration: none !important;
}
.cancel-shipment {
    text-decoration: none !important;
}
.track-button:focus {
    outline:none
}
.cancel-button:focus {
    outline:none
}