.audit-asin-input-box {
  margin-left: 8em;
  width: 15em;
  display: inline-block;
}

.audit-warehouse-input-box {
  margin-left: 5em;
  width: 15em;
  display: inline-block;
}

.audit-select-box {
    margin-left: 1.1em;
    width: 49em;
    display: inline-block;
    background-color: white
}

.audit-date-box {
    margin-left: -1.24em;
    display: flex;
}

.inventory-audit-asin-warehouse-box {
    margin-left: -0.5em;
    display: flex;
}

.audit-start-date-label {
    float: left;
    margin-left: 1.5em;
    color: black;
    width: 8em;
}
.audit-end-date-label {
    float: left;
    margin-left: 8.5em;
    color: black;
    width: 15em;
}
.inventory-audit-asin-input-box {
    float: left;
    margin-left: 6.5em;
}
.inventory-audit-asin-input {
    width: 13.3em;
}
.inventory-audit-asin-label {
    margin-left: 1em;
    color: black;
    width: 5em;
}

.inventory-audit-warehouse-label {
    margin-left: 11.5em;
    color: black;
    width: 10em;
}

.inventory-audit-warehouse-input-box {
    float: left;
    margin-left: 0.4em;
}

.inventory-audit-warehouse-input {
    width: 13.3em;
}

.audit-start-date-selector-box {
    float: left;
    margin-left: 3.6em;
}
.audit-start-date-selector {
    width: 13.4em;
}
.audit-end-date-selector-box {
    float: left;
    margin-left: -1.6em;
}
.audit-end-date-selector {
    width: 13.4em;
}
.inventory-audit-date-params {
    float: left;
    margin-left: 1em;
    color: black;
}

.inventory-audit-input-params {
    float: left;
    margin-left: 1.3em;
    color: black;
}

.audit-button-padding {
    padding-bottom: 5em;
}

.audit-filter-form{ float: left; border: 1px solid #CECECE; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); min-width: 1390px; margin: 0.5em; margin-left: 1em; margin-top: 1em;}
.audit-filter-form .filters {border-right: 1px solid #dfdfdf; color: #444; padding: 1.5em; float: left;min-width:1050px}
.audit-filter-form .filters > div { padding: 0.7em 0; border-bottom: 1px solid #f5f5f5; }
.audit-filter-form .filters > div:first-child { padding-top: 0; }
.audit-filter-form .filters > div:last-child { border: none; padding-bottom: 0}
