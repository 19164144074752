.workflow-stepper {
  overflow-x: auto;
  width: 100%;
}

/* Apply same font styling as Polaris to MaterialUI's Stepper labels. */
.MuiStepLabel-labelContainer > span.MuiStepLabel-label {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-weight: 400;
}

/* Override error text color to match Polaris' styling. */
.MuiStepLabel-labelContainer > span.Mui-error {
  color: #d1322e;
}
