.po-submit-button-padding {
    padding: 0.5em 1em 3.5em 0.5em;
    align-content: center;
}

.po-warehouse-input-box {
    margin-left: 0.8em;
    width: 12em;
}

.po-orderId-input-box {
    margin-left: 2.2em;
    width: 12em;
}

.purchase-order-summary {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 22em;
    text-align: center;
}

.po-details { 
    border: 1px solid #CECECE; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
    margin : 0.5em 15em 1em 1em;
    padding: 1em;
    position: relative;
}

.item-details {
    padding-top: 1em;
}

.total-item-details {
    background-color: #f5f5f5;
    display: inline;
    border: 1px solid #CCC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0em 0em 1em 0em;
    text-align: center;
}

.title {
    margin-bottom: 1em;
    margin-left: -1em;
}

.purchase-order-summary-title {
    padding: 0.5em 1em 0.5em 1em ;
    background-color: #d0d0d0;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
}

.error-msg {
    color: darkred;
    margin-left: 10.8em;
    text-align: center;
    font-size: smaller;
    display: relative;
}

.alert {
    padding: 0.5em;
}

.alert-complete {
    background-color: #C6FF86;
}

.alert-inprocess {
    background-color: #f6d3bb;
}

.alert-error {
    background-color: lightcoral;
}

.po-input-box-height {
    height: 3em;
}

.po-input-params {
    float: left;
    color: black;
    margin-left: 3em;
    margin-right: 3em;
}

.po-orderId-input-params {
    float: left;
    margin-left: 3em;
    color: black;
    margin-right: 4.4em;
}