.inbound-submit-button-padding {
    padding: 0.7em 1.5em 1.2em 0.5em;
}

.total-problem-item-details {
    background-color: #f5f5f5;
    display: inline;
    border: 1px solid #CCC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 1em;
    text-align: center;
}

.problem-item-summary-title {
    padding: 0.5em 1em 0.5em 1em ;
    background-color: #d0d0d0;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width:  17.9em;
}

.problem-item-summary {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 54em;
    text-align: center;
    width: 18em;
}

.problem-item-page-title {
    margin-bottom: 1em;
    margin-left: -1em;
}

.problem-items-input-params {
    float: left;
    margin-left: 3em;
    margin-top: 0.1em;
    margin-right: 3em;
    color: black;
}

.problem-item-details {
    border: 1px solid #CECECE; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
    margin : 0.5em 15em 1em 1em;
    padding: 1em;
    position: absolute;
}

.warehouse-input-box-height {
    height: 2em;
}

.rpi-button {
	text-decoration: none !important;
}
