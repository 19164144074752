.shipment-warehouse-input-box {
    margin-left: 3em;
    height: 5em;
    display: inline-block;
}
.shipment-warehouse-input {
    width: 25em;
}
.shipment-input-params {
    float: left;
    margin-left: 3em;
    margin-top: 1.5em;
    color: black;
}
.shipment-button-padding {
    padding-top: 5em;
}
.shipment-select-params{
    float: left;
    margin-left: 3em;
    margin-top: 1em;
    color: black;
}
.shipment-type-select-buttons{
    margin-left: 2em;
    margin-top: 0.5em;
    display: inline-block;
}

.shipment-filter-form{ float: left; border: 1px solid #CECECE; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); min-width:
900px; margin: 2em; margin-left: 1em; margin-top: 1em;}
.shipment-filter-form .filters {border-right: 1px solid #dfdfdf; color: #444; padding: 2em; float: left;}
.shipment-filter-form .filters > div { padding: 0.7em 0; border-bottom: 1px solid #f5f5f5; }
.shipment-filter-form .filters > div:first-child { padding-top: 0; }
.shipment-filter-form .filters > div:last-child { border: none; padding-bottom: 0}
