.add-parameter-div {
  padding-left: 1em;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.col {
  padding-right: 1em;
  padding-left: 1em;
  width: 50%;
  height: 80%;
  flex: 50%;
  display: block;
  vertical-align: middle;
}
.para-input {
  margin-left: 2em;
  margin-top: 0;
  margin-bottom: 0;
}
.add-parameter-button {
  padding-right: 1em;
}
.hr {
  margin-top: 1em;
}
.config-input-box {
  margin-left: 2em;
  width: 90%;
  height: 2em;
  display: inline-block;
}
.config-input-box:disabled {
  background: #dddddd;
  cursor: not-allowed;
}
.params-input-box {
  margin-left: 2em;
  width: 90%;
  display: inline-block;
}
.config-description {
  margin-left: 2em;
  color: red;
}
.warehouse-input-box {
  margin-left: 3em;
  display: inline-block;
}
.input-params {
  float: left;
  color: black;
}
.single-input-upper-margin {
  padding-top: 2em;
}
.audit-submit-button-padding {
  padding-top: 9em;
}
.column {
  float: left;
  padding: 5px;
}
.column1 {
  width: 30%;
}
.column2 {
  width: 70%;
  padding: 5px;
}
.left {
  width: 36%;
}
.middle {
  width: 10%;
}
.middle1 {
  width: 36%;
}
.right {
  width: 18%;
}
.node-settings-form {
  float: left;
  border: 1px solid #cecece;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  min-width: 50em;
  margin: 2em;
  margin-left: 1em;
  margin-top: 1em;
}
.node-settings-form .filters {
  border-right: 1px solid #dfdfdf;
  color: #444;
  padding: 2em;
  float: left;
  min-width: 50em;
}
.node-settings-form .filters > div {
  padding: 0.7em 0;
  border-bottom: 1px solid #f5f5f5;
}
.node-settings-form .filters > div:first-child {
  padding-top: 0;
}
.node-settings-form .filters > div:last-child {
  border: none;
  padding-bottom: 0;
}

.config-audit-filter-form {
  width: 780px;
}
