.table {
	 width: 100%;
	 table-layout: fixed;
	 border-spacing: 0;
	 padding-left: 1.0rem;
	 overflow-x:auto;
 }
.table tr:hover {background-color: #d3d3d3;}
.info {
	width: 1425px;
	height: 190px;
	border: 1px solid black;
	left: 13px;
	right: 13px;
	border-radius:15px;
	background-color: #d4ebf2;
	padding: 10px 12px;
	margin-left: 7px;
	margin-bottom: 20px;
}
.message{
	padding: 20px 12px;
	font-size: 25px;
}
.small{
	font-size: smaller;
}
.warehouseName{
	font-size: 17px;
}
.LinkStyle{
	text-Decoration: underline;
}
#second{
	font-size: 14px;
    margin-left: 16px;
}