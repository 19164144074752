.inventory-audit-table-layout{
    padding-left:15px;
    padding-right:13px;
}
.p-style{
    color: red;
    fontSize: 13;
}
.link-style {
    color: #0730e6;
    text-decoration: underline;
}
.failed-style{
    color:red;
}
.net-style{
    font-weight:bold;
}