.box-medium {
    width: 80%;
}

.box-container-form {
    width: 100%;
    display: flex;
}

.box-container-form input {
    padding-left: 13px;
}

.box-form-field {
    margin: 30px 20px;
    flex-grow: 3;
}

.box-submit {
    margin : 10px 0px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #000;
}

.config-form-field {
    margin: 15px 0px;
}

.config-label {
    width: 30%;
}

.config-field {
    width: 50%;
}

.config-field-input {
    width: 100%;
}

.display-inline{
    display: inline-block;
}

.box-schema-form {
    margin: 10px 20px;
}

.box-schema-submit {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multifield-flex {
    display:flex;
}

.box-schema-form .config-label {
    width: 15%;
    flex: 0.15;
    padding-top: 14px;
}

.box-schema-form .config-field {
    width: 85%;
    flex: 0.85;
}

.box-schema-form .config-field-input {
    width: 30%;
}

.config-schema-fields-field .fields-input{
    width: 25%;
}

.config-schema-fields-field .schema-field-select{
    width: 20%;
    margin-left: 10px;
}

.config-schema-fields-field .schema-switch-box{
    margin-left: 10px;
}

.config-schema-fields-field .schema-input-small{
    width: 10%;
    margin-left: 10px;
}

.config-schema-fields-field .schema-input-medium{
    width: 21%;
    margin-left: 10px;
}

.box-schema-form input {
    padding-left: 10px;
}

.error-input {
    border: 2px solid red;
    border-radius: 2px;
}

.error-fields input {
    border: 2px solid red;
    border-radius: 2px;
}

.guide-link {
    font-size: medium;
}