.input-params {
    float: left;
    margin-left: 3em;
    color: black;
}

.button-padding {
    padding-top: 5.3em;
}

.filter-form { background-color: rgb(253, 253, 253); float: left; border: 1px solid #CECECE; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); min-width: 702px; margin: 2em; margin-left: 1em; margin-top: 1em;}
.filter-form .filters {border-right: 1px solid #dfdfdf; color: #444; padding: 2em; float: left;}
.filter-form .filters > div { padding: 0.7em 0; border-bottom: 1px solid #f5f5f5; }
.filter-form .filters > div:first-child { padding-top: 0; }
.filter-form .filters > div:last-child { border: none; padding-bottom: 0}